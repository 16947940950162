import { getConfigurations } from "@services/configuration.service";
import { isValidResponseEntity } from "@ui/helpers/utils";
import { appDownloadLink } from "@helpers/constants";
import { Image } from "@ui/atoms";
import { Button } from "@ui/atoms/design-system";
import { useState, useEffect } from "react";
import { useInvestorContext } from "@context/InvestorContextProvider";
import { KycStatus } from "@helpers/enums/kycEnums";
import { getAuth } from "@services/identity.service";
import texts from "@molecules/BottomNavigation/en.json";
import { triggerCustomEvent } from "@helpers/miscelleanous";
import { gaEventNameEnum } from "@helpers/enums/gaEventNameEnum";
import { getMobileOS } from "@helpers/utils";

const AppDownloadNudge = () => {
    const { investorCache } = useInvestorContext();
    const [showAppDownloadNudge, setShowAppDownloadNudge] = useState(false);
    const [isKycVerified, setIsKycVerified] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        if (auth && !auth?.accountType) {
            setIsKycVerified(false);
        } else if (investorCache) {
            setIsKycVerified(investorCache?.kycStatus === KycStatus.VERIFIED);
        }
    }, [investorCache?.kycStatus]);

    useEffect(() => {
        const getAppNudgeConfig = async () => {
            const appNudgeInLocalStorage = localStorage.getItem("AppDownloadNudgeOnMobileView");
            if (appNudgeInLocalStorage != null) {
                setShowAppDownloadNudge(appNudgeInLocalStorage === 'true');
            } else {
                const response = await getConfigurations("General", "AppDownloadNudgeOnMobileView");
                if (isValidResponseEntity(response) && response?.entity?.length > 0) {
                    localStorage.setItem("AppDownloadNudgeOnMobileView", response?.entity?.[0]?.value === 'active');
                    setShowAppDownloadNudge(response?.entity?.[0]?.value === 'active');
                }
            }
        }

        if (isKycVerified) {
            getAppNudgeConfig();
        }
    }, [isKycVerified]);

    const closeAppDownloadNudge = () => {
        triggerCustomEvent(gaEventNameEnum.CANCELLED_APP_DOWNLOAD_NUDGE, { mobileOs: getMobileOS() });
        localStorage.setItem("AppDownloadNudgeOnMobileView", false);
        setShowAppDownloadNudge(false);
    }

    const onClickAppDownload = () => {
        triggerCustomEvent(gaEventNameEnum.CLICKED_APP_DOWNLOAD_NUDGE, { mobileOs: getMobileOS() });
        window.open(appDownloadLink, '_blank');
    }

    return showAppDownloadNudge
        ? (
            <div className={`md:hidden ${showAppDownloadNudge ? 'flex' : 'hidden'} w-min mb-3 rounded-r-full pl-4 pr-3 py-[6px] border-primary-50 border-y-1 border-r-1 bg-primary-20 items-center gap-x-4`}>
                <Button
                    buttonText={texts?.UseTheMobileApp}
                    buttonSize='extraSmall'
                    buttonType='ghost'
                    onClick={onClickAppDownload}
                />
                <div className='cursor-pointer w-5 h-5 flex justify-center items-center' onClick={closeAppDownloadNudge}>
                    <Image src="/images/icons/Cancel_Grey.svg" alt="close" />
                </div>
            </div>
        )
        : null;
}

export default AppDownloadNudge;